// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-polityka-js": () => import("./../src/pages/polityka.js" /* webpackChunkName: "component---src-pages-polityka-js" */),
  "component---src-pages-proces-js": () => import("./../src/pages/proces.js" /* webpackChunkName: "component---src-pages-proces-js" */),
  "component---src-pages-pytania-js": () => import("./../src/pages/pytania.js" /* webpackChunkName: "component---src-pages-pytania-js" */),
  "component---src-pages-realizacje-js": () => import("./../src/pages/realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */),
  "component---src-pages-wycena-js": () => import("./../src/pages/wycena.js" /* webpackChunkName: "component---src-pages-wycena-js" */)
}

